body {
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
body * {
  font-family: "Poppins", sans-serif !important;
  
}

code {
  font-family: "Poppins", sans-serif;
}

.MuiFormControl-root-MuiTextField-root-root {
  background: "#fff";
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  background: "#fff";
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d9d9d9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #17cc97;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #17cc80;
}
